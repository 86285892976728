@import '../../styles/variables.scss';

.journey {
    display: flex;

    &--light .journey__text-main {
        font-family: $font-family;
    }

    &__text-main, &__text-sub {
        display: block;
        width: 100%;
        margin: 0px;
    }

    &__text-main {
        margin-bottom: 5px;
        font-family: $font-family-main-text;
        font-size: $font-size-main-text;
    }
    
    &__text-sub {
        font-family: $font-family-sub-text;
        font-size: $font-size-sub-text;
    }

    &__arrow {
        display: inherit;
        width: 100px;
        align-items: center;
        justify-content: center;
        img {
            min-width: 56px;
            height: 27px;
        }
    }
}