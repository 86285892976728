@import '../../styles/variables.scss';

.app-root {
  padding: 15px 15px 0;
  background: $body-background;

  &__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    div {
      margin: 0 auto;
    }
  }
}
