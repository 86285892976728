$body-background: #440F11;
$foreground-default-color: #fff;
$font-family: 'LNER Book';
$font-family-bold: 'LNER Bold';


// Journey 

$font-family-main-text: $font-family-bold;
$font-size-main-text: 30px;

$font-family-sub-text: $font-family;
$font-size-sub-text: 16px;


// Card
$card-background: #282828;

// Journey Details
$travel-time-background: #fff;
$travel-time-foreground: #111;

// Order Details
$order-details-total-background: #C8102E;
$order-details-total-foreground: #fff;



