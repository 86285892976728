.ticket-card {
    background: white;
    width: 100%;
    border-radius: 10px;
    border-bottom: 1px dotted black;
    position: relative;

    .ticket-header {
        background: #CE0E2D;
        width: 100%;
        border-radius: 10px 10px 0 0;
        display: block;
        padding: 10px 15px;
        box-sizing: border-box;
        font-weight: bold;

        .depart-date {
            float: right;
        }
    }

    .ticket-content {
        color: black;
        display: flex;
        padding: 15px;
    }

    .ticket-bite {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #440F11;
        position: absolute;

        &.upperLeft {
            left: -10px;
            top: -10px;
        }

        &.upperRight {
            right: -10px;
            top: -10px;
        }

        &.bottomLeft {
            left: -10px;
            bottom: -10px;
            z-index: 2;
        }

        &.bottomRight {
            right: -10px;
            bottom: -10px;
            z-index: 2;
        }
    }
}

.journey-details {
    flex-grow: 1;

    small {
        font-size: 10px;
    }

    .origin,
    .destination,
    .passenger-detail,
    .fare-detail {
        margin-bottom: 20px;

        >* {
            display: block;
        }

    }

    .passenger-detail div {
        display: inline-block;

        // &:last-child {
        //     margin-left: 20px;
        // }

        >* {
            display: block;
        }

    }

    .bold {
        font-weight: bold;
    }
}

.order-details {
    display: block;
    flex-grow: 2;

    small {
        font-size: 10px;
    }

    >div {
        margin-bottom: 20px;

        >* {
            display: block;
        }
    }

    >* {
        display: block;
    }

    .bold {
        font-weight: bold;
    }
}

.total {
    background: #DADADA;
    height: 30px;
    text-align: right;
    padding: 10px 55px 0;
    color: black;
    border-radius: 10px;
}

.fare {
    display: inline-block !important;
}

.info-toggle {
    display: inline-block !important;
    position: absolute;
    background: url('../../assets/information-icon.svg') no-repeat center center;
    border-radius: 100%;
    background-color: #CE0E2D;
    width: 16px;
    height: 16px;
    margin-left: 15px;
    background-size: 15%;
}

.restrictions-modal {
    background-color: #440F11 !important;
    width: 100% !important;
    box-sizing: border-box;
    font-family: "LNER Bold";

    .restrictions {
        width: 100%;
        white-space: break-spaces;

        .restriction {
            word-break: break-word;
        }

        a {
            color: white
        }
    }
}