
@import '../../styles/variables.scss';

.changes-modal {
    background: $card-background;
    border: 1px solid #fff;
    border-radius: 5px;
    width: 100%;
    padding: 15px;

    &__title {
        svg {
            margin-right: 10px;
        }
        display: flex;
        align-items: center;
        font-size: 16px;
        margin: 0px;
    }

    
}