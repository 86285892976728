@import '../../styles/variables.scss';

.journey-details {
    
    p {
        margin: 0;
    }
    
    &__travel-time {
        display: inline-block;
        border-radius: 5px;
        background: $travel-time-background;
        padding: 5px 10px;
        margin: 10px 0 0;
        color: $travel-time-foreground;
    }

    ul {
        padding: 15px 0 0;
        margin: 0;
        padding-inline-start: 30px;
        li {
            word-wrap: break-word;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}
