@import '../../styles/variables.scss';

.card {
    border-radius: 5px;
    background: $card-background;
    padding: 10px;
    margin: 0 0 10px;
    h5 {
        margin: 0;
    }
}