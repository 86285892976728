@import '../../styles/variables.scss';

.button {
    border-radius: 5px;
    border: 0;
    padding: 10px 15px;
    width: 100%;
    margin-bottom: 5px;
    background: white;

    &--download {
        background: $order-details-total-background;
        color: $order-details-total-foreground;
    }

    &--passbook {
        padding: 2px 5px 0;
        background: black;
        img {
            width: 100%;
            height: 38px;            
        }
    }
}