header {
    margin-bottom: 14px;

    img {
        max-width: 75px;
        max-height: 30px;
    }

    .complete-purchase{
        font-size: 32px;
    }
}