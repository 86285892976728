@font-face {
    font-family: 'LNER Book';
    src: url('LNER-Book.woff2') format('woff2'),
        url('LNER-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LNER Bold';
    src: url('LNER-Bold.woff2') format('woff2'),
        url('LNER-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

